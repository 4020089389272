import React from 'react'
import "./modal.scss"


const Modal = ({ selectedImg, setSelectedImg }) => {

    const handleClick = (e) => {
        if(e.target.classList.contains("backdrop")){
            setSelectedImg(null)
        }   
    }

    return (
        <div className="backdrop" onClick={handleClick}>
            <img src={selectedImg} alt="" />
        </div>
    )
}

export default Modal
